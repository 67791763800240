@tailwind base;
@tailwind components;
@tailwind utilities;

/* import 'bootstrap/dist/css/bootstrap.min.css'; */
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit !important;
}

* {
  outline: none !important;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
  /* list-style: none; */
}
a:focus,
a:hover {
  text-decoration: none !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 150px !important;
}
.css-1uccc91-singleValue {
  color: #6a757e !important;
  font-weight: 700;
}
a {
  text-decoration: none !important;
}
body {
  font-size: 12pt !important;
}
.wrap {
  padding: 15px;
  border: solid thin #eae7e7;
  border-radius: 6px;
}
.badge-primary {
  @apply tw-bg-primaryColor2 tw-text-white;
}
.badge-secondary {
  @apply tw-bg-warning tw-text-white;
}
.linkEdit {
  @apply tw-text-white tw-bg-primary tw-py-2 tw-px-4 tw-rounded-md tw-whitespace-nowrap hover:tw-text-white;
}
.linkDetail {
  @apply tw-text-white tw-bg-info tw-py-2 tw-px-4 tw-rounded-md tw-whitespace-nowrap hover:tw-text-white;
}
