.my20 {
  margin: 20px auto;
}
.title {
  font-size: 20pt;
  margin-bottom: 10px;
}
.imgNews {
  width: 100%;
}
.imgTitle {
  margin: 5px 0 8px;
  text-align: center;
  color: #a29d9a;
}
.teaser {
  margin: 12px 0 0;
}
