.titleInfo {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
  text-align: center;
}
.subTitleInfo {
  font-size: 14px;
  margin-bottom: 30px;
  text-align: center;
}
.btnCreate {
  color: red;
  background-color: #fff;
  border: solid thin #d3d3d3;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0 auto;
  display: block;
  width: 150px;
  font-weight: 400;
  text-align: center;
}
.imgInfo {
  width: 55%;
  margin: 40px auto;
  display: block;
}
.wrapListNews {
  border: solid thin #d3d3d3;
  border-radius: 6px;
  margin-top: 24px;
  padding: 16px;
}
.titleListNews{
  font-size: 20pt;
}