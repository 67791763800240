.sectionTitle {
  width: 100%;
  position: relative;
  font-family: Roboto Slab, serif;
  margin: 20px 0 20px;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  overflow: hidden;
}
.sectionTitle span {
  font-size: 23px;
  background: #fff;
  padding: 0 15px;
  z-index: 2;
  position: relative;
}
.sectionTitle > :before {
  left: 100%;
  -webkit-transform: translateY(-50%) skew(40deg);
  -ms-transform: translateY(-50%) skew(40deg);
  transform: translateY(-50%) skew(40deg);
}
.sectionTitle > :after,
.sectionTitle > :before {
  position: absolute;
  top: 50%;
  height: 3px;
  width: 100rem;
  background: #333;
  content: " ";
}
.sectionTitle > :after {
  right: 100%;
  -webkit-transform: translateY(-50%) skew(-40deg);
  -ms-transform: translateY(-50%) skew(-40deg);
  transform: translateY(-50%) skew(-40deg);
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.trendingList {
  padding-left: 0;
  list-style: none;
}
.trendingList li {
  padding-left: 25px;
  font-size: 15px;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
  position: relative;
}
.trendingList li:before {
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 0;
  content: "";
  background: red;
  height: 8px;
  width: 8px;
  border-radius: 100%;
}
.trendingList a {
  color: #333;
}
