#logregForms {
  width: 412px;
  margin: 10vh auto;
  background-color: #f3f3f3;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
#logregForms form {
  width: 100%;
  max-width: 410px;
  padding: 15px;
  margin: auto;
}
#logregForms .formControl {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
#logregForms .formControl:focus {
  z-index: 2;
}
#logregForms .formSignin input[type="email"] {
  margin-bottom: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#logregForms .formSignin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* .socialLogin{

} */
.socialLogin {
  margin: 0 auto;
  text-align: center;
}
#logregForms .socialBtn {
  font-weight: 100;
  color: white;
  font-size: 0.9rem;
  margin: 0 3px 15px;
}

#logregForms a {
  display: block;
  padding-top: 10px;
  color: lightseagreen;
}

#logreg-form .lines {
  width: 200px;
  border: 1px solid red;
}

#logregForms button[type="submit"] {
  margin-top: 10px;
}

#logregForms .facebookBtn {
  background-color: #3c589c;
}

#logregForms .googleBtn {
  background-color: #df4b3b;
}

#logregForms .form-reset,
#logregForms .form-signup {
  display: none;
}

#logregForms .form-signup .socialBtn {
  width: 210px;
}

#logregForms .form-signup input {
  margin-bottom: 2px;
}

.form-signup .social-login {
  width: 210px !important;
  margin: 0 auto;
}

/* Mobile */

@media screen and (max-width: 500px) {
  #logregForms {
    width: 300px;
  }

  #logregForms .social-login {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #logregForms .socialBtn {
    font-size: 1.3rem;
    font-weight: 100;
    color: white;
    width: 200px;
    height: 56px;
  }
  #logregForms .socialBtn:nth-child(1) {
    margin-bottom: 5px;
  }
  #logregForms .socialBtn span {
    display: none;
  }
  #logregForms .facebookBtn:after {
    content: "Facebook";
  }

  #logregForms .googleBtn:after {
    content: "Google+";
  }
}
