.wrapMobileNav {
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  background: aliceblue;
  z-index: 9;
}
.close {
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #676262;
  line-height: 1;
}
.menu {
  font-size: 14pt;
  margin: 15px 0;
}
.ulMobileNav {
  list-style: none;
  padding: 0;
}
.ulMobileNav li {
  padding: 5px 0;
  color: #6b6565;
  font-size: 12pt;
}
.ulMobileNav li a {
  color: #6b6565;
}
